import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";

/* DayJs */
import * as dayjs from 'dayjs'
import 'dayjs/locale/ko'

/* vue-i18n */
import { createI18n } from "vue-i18n"; // vue-i18n import
import messages from "./i18n"; // message import

/* ionic */
import { IonicVue } from '@ionic/vue'

/* swiper */
import SwiperClass, { Pagination, Navigation } from 'swiper'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
// use swiper modules
SwiperClass.use([Pagination, Navigation]);

/* common css */
import './assets/css/basic_public.css';
import './assets/css/layout.css';
import './assets/css/alert.css';
// import './assets/css/popup_v1.0.1.css';
// import './assets/css/landing_v1.1.0.css';
import './assets/css/mypage.css';
import './assets/css/contact.css';

import './assets/css/base_new.css';
import './assets/css/base.css';
import './assets/css/swiper.css';
import './assets/css/main2.css';
import './assets/css/contents.css';
import './assets/css/view.css';

import './assets/css/main3.css';

const app = createApp(App).use(router).use(store).use(IonicVue).use(VueAwesomeSwiper);

/* DayJs */
dayjs.locale('ko')

if (process.env.NODE_ENV == 'development')
  app.provide('$dayjs', dayjs) // provide $dayjs
else app.provide('$dayjs', dayjs.default) // provide $dayjs

app.config.globalProperties.$dayjs = dayjs
/* \DayJs */

// create vue-i18n object
const i18n = createI18n({
  // something vue-i18n options here ...
  warnHtmlMessage: false, // disable warning HTML in message
  legacy: false,
  locale: "Korea",
  fallbackLocale: "English",
  messages,
});

app.use(i18n);

app.mount('#app')

// router.isReady().then(() => {
//   app.mount('#app')
// })
