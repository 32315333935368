// store/index.js
import { createStore } from "vuex";
import { loadingController } from "@ionic/vue";
import { callAPI } from "@/common/commonAPI";
import { commonModule } from "./modules/commonModule";

export interface RootState {
  USER_DETAIL_01_01_NAME: string,
  USER_DETAIL_01_02_NAME: string,
  USER_DETAIL_02_NAME: string,
  admin_popup_show: boolean,
  user_detail_01_01: string | object,
  user_detail_01_02: string | object,
  user_detail_02: string | object,
  user_detail_02_init: object,
  user_detail_02_file: any,
  user_detail_02_file_src: any,
  mypage: string | object,
  mypage_init: object,
  booth_view: string | object,
}

export default createStore({
  modules: { 
    commonModule: commonModule
  },
  state: {
    USER_DETAIL_01_01_NAME: "HAUTE_RIUM_USER_DETAIL_01_01",
    USER_DETAIL_01_02_NAME: "HAUTE_RIUM_USER_DETAIL_01_02",
    USER_DETAIL_02_NAME: "HAUTE_RIUM_USER_DETAIL_02",
    admin_popup_show: false,
    /**
     * 상태값
     * "" ->  api 호출 전
     * {} ->  api 호출 후 값이 없음
     * {~} -> api 호출 후 값이 있음
     */
    user_detail_01_01: "",
    user_detail_01_02: "",
    user_detail_02: "",
    user_detail_02_init: {
      "gender": "",
      "birthYear": 2000,
      "companyName": "",
      "department": "",
      "position": "",
      "about": ""
    },
    user_detail_02_file: null,
    user_detail_02_file_src: null,
    mypage: "",
    mypage_init: {
      phone: {
        countryCode: "",
        areaCode: "",
        prefix: "",
        lineNumber: ""
      },
      userDetail: {}
    },
    booth_view: "",
  },
  getters: {
    getAdminPopupShow(state){
      return state.admin_popup_show;
    },
    getUserDetail0101(state){
      if( state.user_detail_01_01 == "" ){
        if( sessionStorage.getItem(state.USER_DETAIL_01_01_NAME) == null ){
          return "";
        }else {
          return sessionStorage.getItem(state.USER_DETAIL_01_01_NAME);
        }
      }
      return state.user_detail_01_01;
    },
    getUserDetail0102(state){
      if( state.user_detail_01_02 == "" ){
        if( sessionStorage.getItem(state.USER_DETAIL_01_02_NAME) == null ){
          return "";
        }else {
          return sessionStorage.getItem(state.USER_DETAIL_01_02_NAME);
        }
      }
      return state.user_detail_01_02;
    },
    getUserDetail02(state){
      if( state.user_detail_02 == "" ){
        if( sessionStorage.getItem(state.USER_DETAIL_02_NAME) == null ){
          return state.user_detail_02_init;
        }else {
          return JSON.parse(sessionStorage.getItem(state.USER_DETAIL_02_NAME)!);
        }
      }
      return state.user_detail_02;
    },
    getUserDetail02File(state){
      return state.user_detail_02_file;
    },
    getUserDetail02FileSrc(state){
      return state.user_detail_02_file_src;
    },
    getMypage(state){
      if( state.mypage == "" ){
        return state.mypage_init;
      }else {
        return state.mypage;
      }
    }
  },
  mutations: {
    setAdminPopupShow(state, value:boolean) {
      if( value ){
        //scroll 금지
        const target = window.document.getElementsByTagName("html")[0];
        target.style.overflowX = 'hidden';
        target.style.overflowY = 'hidden';
      }else {
         //scroll 열기
        const target = window.document.getElementsByTagName("html")[0];
        target.style.overflowX = 'auto';
        target.style.overflowY = 'auto';
      }
      state.admin_popup_show = value;
    },
    setUserDetail0101(state, value) {
      if( value == "" ){
        state.user_detail_01_01 = value;
        sessionStorage.removeItem(state.USER_DETAIL_01_01_NAME);
      }else{
        state.user_detail_01_01 = value;
        sessionStorage.setItem(state.USER_DETAIL_01_01_NAME, value);
      }
    },
    setUserDetail0102(state, value) {
      if( value == "" ){
        state.user_detail_01_02 = value;
        sessionStorage.removeItem(state.USER_DETAIL_01_02_NAME);
      }else{
        state.user_detail_01_02 = value;
        sessionStorage.setItem(state.USER_DETAIL_01_02_NAME, value);
      }
    },
    setUserDetail02(state, value) {
      if( value == "" ){
        state.user_detail_02 = value;
        sessionStorage.removeItem(state.USER_DETAIL_02_NAME);
      }else{
        state.user_detail_02 = value;
        sessionStorage.setItem(state.USER_DETAIL_02_NAME, JSON.stringify(value));
      }
    },
    setUserDetail02File(state, value) {
      state.user_detail_02_file = value;
    },
    setUserDetail02FileSrc(state, value) {
      state.user_detail_02_file_src = value;
    },
    setMypage(state, value) {
      state.mypage = value;
    },
    setBoothView(state, value){
      state.booth_view = value
    },
  },
  actions: {
    //get booth view
    async getBoothView({ getters, commit, dispatch }, idx:string ){

      dispatch("showLoading");

      const url = `/booth_contents/${idx}`;
      const param = {};
      const token = getters.getAuth;
      try{
        const result = await callAPI(url, param, "get", token.accessToken);

        commit("setBoothView", result.boothContents);

        return true;
        
      }catch(e: any){
        return false;
      }
      finally{
        dispatch("hideLoading");
      }
    },
  },
});
 