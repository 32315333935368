import store from '@/store';
import { computed } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFoundPage.vue'),
  },
  {
    path: "/",
    name: 'Main',
    component: () => import('../views/MainPage2.vue'),
    beforeEnter: () => {
      // window.location.href = "https://hauterium.com/beta/";
    }
  },
  {
    path: '/event/list',
    name: 'EventUserList',
    component: () => import('../views/front/EventList.vue'),
  },
  {
    path: '/event/detail/:id',
    name: 'EventDetail',
    component: () => import('../views/front/EventDetail.vue'),
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import('../views/front/AboutPage.vue'),
  },
  {
    path: '/plan/price',
    name: 'PlanPrice',
    component: () => import('../views/front/PlanPrice.vue'),
  },
  {
    path: '/download',
    name: 'DownLoad',
    component: () => import('../views/front/DownLoad.vue'),
  },
  {
    path: '/install',
    name: 'InstallPage',
    component: () => import('../views/front/InstallPage.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/front/FAQ.vue'),
  },
  {
    path: '/contact',
    name: 'ContactNew',
    component: () => import('../views/front/ContactNew.vue'),
  },
  {
    path: '/features:type',
    name: 'FeatureNew',
    props:true,
    component: () => import('../views/front/FeaturePage_new.vue'),
  },
  {
    path: '/feature/all',
    name: 'FeatureAll',
    component: () => import('../views/front/FeatureAll.vue'),
  },
  {
    path: '/feature/1',
    name: 'Feature1',
    component: () => import('../views/front/FeaturePage1.vue'),
  },
  {
    path: '/feature/2',
    name: 'Feature2',
    component: () => import('../views/front/FeaturePage2.vue'),
  },
  {
    path: '/feature/3',
    name: 'Feature3',
    component: () => import('../views/front/FeaturePage3.vue'),
  },
  {
    path: '/feature/4',
    name: 'Feature4',
    component: () => import('../views/front/FeaturePage4.vue'),
  },
  {
    path: '/feature/5',
    name: 'Feature5',
    component: () => import('../views/front/FeaturePage5.vue'),
  },
  {
    path: '/feature/6',
    name: 'Feature6',
    component: () => import('../views/front/FeaturePage6.vue'),
  },
  {
    path: '/feature/7',
    name: 'Feature7',
    component: () => import('../views/front/FeaturePage7.vue'),
  },
  {
    path: '/feature/8',
    name: 'Feature8',
    component: () => import('../views/front/FeaturePage8.vue'),
  },
  /* sign */
  {
    path: '/sign',
    name: 'Start',
    component: () => import('../views/sign/SignInStartPage.vue'),
    beforeEnter: async (to:any, from:any, next:any) => {
      //guard
      const user_info = computed(() => store.getters.getUserInfo);
      if(user_info.value != null)
      {
        next("/");
      }else {
        next();
      }

    }
  },
  {
    path: '/sign/signin',
    name: 'SignIn',
    component: () => import('../views/sign/SignInPage.vue'),
    beforeEnter: async (to:any, from:any, next:any) => {
      //guard
      const user_info = computed(() => store.getters.getUserInfo);
      if(user_info.value != null)
      {
        next("/");
      }else {
        next();
      }

    }
  },
  {
    path: '/sign/signup',
    name: 'SignUp',
    component: () => import('../views/sign/SignUpPage.vue'),
  },
  {
    path: '/sign/new_password',
    name: 'NewPassword',
    component: () => import('../views/sign/NewPasswordPage.vue'),
  },
  {
    path: '/sign/send_new_password',
    name: 'EmailNewPassword',
    component: () => import('../views/sign/EmailNewPasswordPage.vue'),
  },
  {
    path: '/sign/send_confirm',
    name: 'EmailConfirmSend',
    component: () => import('../views/sign/EmailConfirmSendPage.vue'),
  },
  {
    path: '/sign/send_confirm_need',
    name: 'EmailConfirmNeed',
    component: () => import('../views/sign/EmailConfirmNeedPage.vue'),
  },
  {
    path: '/sign/send_confirm_success',
    name: 'EmailConfirmSuccess',
    component: () => import('../views/sign/EmailConfirmSuccessPage.vue'),
  },
  {
    path: '/sign/finish',
    name: 'Finish',
    component: () => import('../views/sign/FinishPage.vue'),
  },
  {
    path: '/sign/signup_step_01',
    name: 'SignUpStep01',
    component: () => import('../views/sign/SignUpStep01Page.vue'),
  },
  {
    path: '/sign/signup_step_02',
    name: 'SignUpStep02',
    component: () => import('../views/sign/SignUpStep02Page.vue'),
  },
  {
    path: '/sign/signup_step_03',
    name: 'SignUpStep03',
    component: () => import('../views/sign/SignUpStep03Page.vue'),
  },
  /* mypage */
  {
    path: '/mypage',
    name: 'MypageParent',
    //component: () => import('../views/mypage/MypageParentPage.vue'),
    beforeEnter: async (to:any, from:any, next:any) => {
      //guard
      const token = computed(() => store.getters.getAuth);
      if( token.value.accessToken == "" ){
        const locale = computed(() => store.getters.getLocale);
        if( locale.value == "English" ){
          alert("Login is required.");  
        }else {
          alert("로그인이 필요합니다.");
        }

        //로그인으로
        next("/sign");
      }else {
        next();
      }
    },
    children: [
      {
        path: '',
        name: 'Info',
        component: () => import('../views/mypage/children/MypageInfoPage.vue'),
      },
      {
        path: '/mypage/bizcard',
        name: 'BizCard',
        component: () => import('../views/mypage/children/BizCard.vue'),
      },
      {
        path: '/mypage/favbooth',
        name: 'FavBooth',
        component: () => import('../views/mypage/children/FavBooth.vue'),
      },
    ],
  },
  {
    path: '/sign/contact',
    name: 'Contact',
    component: () => import('../views/contact/ContactPage.vue'),
  },
  {
    path: '/booth_view',
    name: 'BoothView',
    component: () => import('../views/front/BoothViewPage.vue'),
    beforeEnter: async (to:any, from:any, next:any) => {

      const idx = to.query.val;

      const result = await store.dispatch("getBoothView", idx);

      if( result ){
        next();
      }else {
        const locale = computed(() => store.getters.getLocale);
        if( locale.value == "English" ){
          alert("Failed to get information.\nPlease use it again soon.");  
        }else {
          alert("정보를 가져오지 못하였습니다.\n잠시 후 다시 이용해주세요.");
        }
        next(false);
      }
    }
  },
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(process.env.BASE_URL),
  routes, // short for `routes: routes`
})

export default router;