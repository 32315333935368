import axios from "axios";

const setLocalStorage = (key: string, item: string) => {
    localStorage.setItem(key, item);
}

const getLocalStorage = (key: string) => {
    return localStorage.getItem(key);
}

const dev_mode = false;

// const ID_NAME = "GASKO_id";

// const parseId = (str: string) => {
//     const temp = str.split("|");
//     if( temp.length < 2 ){
//         return str;
//     }else {
//         return temp[1];
//     }
// }

// export const setId = (id: string) => {
//     setLocalStorage(ID_NAME, parseId(id));
// }

// export const getId = () => {
//     return getLocalStorage(ID_NAME);
// }

// export const logout = () => {
//     localStorage.removeItem(ID_NAME);
// }

const BASE_URL = "https://slash.hauterium.com" //pro
let URL = BASE_URL;
// let URL = "https://rarely-sought-snapper.ngrok-free.app" //pro 2
// let URL = ""; //dev

if( process.env.NODE_ENV == "development" ){
    URL = process.env.VUE_APP_ROOT_API_URL;
}

export const getRootURL = () => {
    return URL;
}

export const getDeviceType = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

    if( isMobile ){
        const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);

        if( isIOS ){
            return "ios";
        }else {
            return "android";
        }
    }else {
        return "web";
    }
}

export const callAPI = async (g_url: string, param: any, type = "post", token = "", cache = false, other = false ) => {
    // let url = '/users/login'
    // let param = {
    //     uid : "employee2",
    //     password : "1234",
    //     device : "web"
    // }



    let headers;

    if( cache ){
        headers = {
            'Cache-Control': 'no-store',
            Pragma: 'no-store',
            Expires: '0',
            Authorization: "Bearer " + token,
        }
    }else {
        headers = {
            Authorization: "Bearer " + token,
        }
    }

    let url = "";

    if(dev_mode)
    {
        url = "https://rarely-sought-snapper.ngrok-free.app" + g_url;
    }
    else{
        url = URL + g_url;
    }


    //let url = "https://rarely-sought-snapper.ngrok-free.app" + g_url;

    if( other ){
        url = g_url;   
    }

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

    if( isMobile ){
        const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);

        if( isIOS ){
            param.os = "ios";
            param.device = "ios";
        }else {
            param.os = "android";
            param.device = "android";
        }
    }else {
        param.os = "web";
        param.device = "web";
    }

    if( type == "get" ){
        return axios.get(url, { params: param, headers : headers })
            .then(function(response) {
                if( !(response.status == 200 || response.status == 201) ){
                    throw response;
                }
                return response.data
            })
            .catch(function(error) {
                throw error.response;
            });
    } 
    else if( type == "put" ) {
        return axios.put(url, param, { headers : headers })
            .then(function(response) {
                if( !(response.status == 200 || response.status == 201) ){
                    // throw new Error(response.data);
                    throw response;
                }
                return response.data
            })
            .catch(function(error) {
                // throw new Error(error);
                throw error.response;
            });
    }else if( type == "patch" ) {
        return axios.patch(url, param, { headers : headers })
            .then(function(response) {
                if( !(response.status == 200 || response.status == 201) ){
                    // throw new Error(response.data);
                    throw response;
                }
                return response.data
            })
            .catch(function(error) {
                // throw new Error(error);
                throw error.response;
            });
    } else if( type == "delete" ) {
        return axios.delete(url, { headers : headers })
            .then(function(response) {
                if( !(response.status == 200 || response.status == 201) ){
                    // throw new Error(response.data);
                    throw response;
                }
                return response.data
            })
            .catch(function(error) {
                // throw new Error(error);
                throw error.response;
            });
    } else {
        return axios.post(url, param, { headers : headers })
            .then(function(response) {
                if( !(response.status == 200 || response.status == 201) ){
                    // throw new Error(response.data);
                    throw response;
                }
                return response.data
            })
            .catch(function(error) {
                // throw new Error(error);
                throw error.response;
            });
    }
}

export const fileUploadAPI = async (g_url: string, param: any, token: string ) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization:
        "Bearer " + token
    }

    const url = URL + g_url;

    return axios.put(url, param, { headers : headers })
        .then(function(response) {
            if( !(response.status == 200 || response.status == 201) ){
                // throw new Error(response.data);
                throw response;
            }
            return response.data
        })
        .catch(function(error) {
            // throw new Error(error);
            throw error.response;
        });
}

export const fileDirectUploadAPI = async (g_url: string, file: File, token: string, callback_url: string ) => {
    const headers = {
        "Content-Type": file.type,
        "Content-Disposition": "inline"
        // Authorization:
        // "Bearer " + token
    }

    const url = g_url;

    return axios.put(url, file, { headers : headers })
        .then(async function(response) {
            if( !(response.status == 200 || response.status == 201) ){
                // throw new Error(response.data);
                throw response;
            }

            // "path": "https://slash.hauterium.com/upload/94f36d79-f964-499e-871d-29dd5c9adf71",
            //파일 업로드가 성공하면 성공요청을 보내야한다.

            const headers = {
                Authorization: "Bearer " + token,
            }
            const regexAllCase = new RegExp(BASE_URL, "gi")
            const temp_url = URL + callback_url.replace(regexAllCase,"");
            await axios.patch(temp_url, {}, { headers : headers });
            
            return response.data
        })
        .catch(function(error) {
            // throw new Error(error);
            throw error.response;
        });
}

export function getCompany(data: string){
    const temp = data.split("commute/");
    let company = data;

    if( temp.length > 1 ){
      company = temp[1];
    }
    return company;
}

export const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

//동기 테스트
export const sleep = function (ms:number) {
    return new Promise((r) => setTimeout(r, ms));
}

/**
 * 비밀번호 체크
 * @param pw 
 * @returns
 *  true - 규칙 맞음
 *  fales - 규칙 틀림
 */
export const validatePw = function (pw:string){

    //최소 8자리 이상 영문 대소문자, 숫자, 특수문자가 각각 1개 이상 (패스워드 체크시 활용)
    // const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,16}$/;

    //최소 6자리 이상 영문, 숫자, 특수문자가 각각 1개 이상
    const regex = /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{6,16}$/;

    if( regex.exec(pw) ) {
        return true;
    }else{
        return false;
    }
};

/**
 * 숫자 체크
 * @param tel 
 * @returns
 *  true - 규칙 맞음
 *  fales - 규칙 틀림
 */
export const validateTel = function (tel:string){

    //숫자
    const regex = /^[0-9]+$/;

    if( regex.exec(tel) ) {
        return true;
    }else{
        return false;
    }
};

/**
 * 이메일 체크
 * @param tel 
 * @returns
 *  true - 규칙 맞음
 *  fales - 규칙 틀림
 */
export const validateEmail = function(email:string) {
    // return email.match(/^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
    const regex = new RegExp('[a-z0-9]+@[a-z\\-]+\\.[a-z]{2,3}');
    return regex.test(email);
}